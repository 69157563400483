import React from "react";

const Contact = () => {
  return (
    <div className="container">
      <h1>Contact Me</h1>
      <p>
        If you have any questions or would like to get in touch, please contact
        us:
      </p>
      <ul>
        <li>adambukojemski@mail.com</li>
        <li>Mobile Number: +62 813-3936-2630</li>
      </ul>
    </div>
  );
};

export default Contact;
