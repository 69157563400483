import React from "react";

const Home = () => {
  return (
    <div className="home-container">
      <section className="service-introduction">
        <h1 className="title">Chess Mastery for Discerning Adults</h1>
        <hr className="divider" />
        <div className="image-container">
          <img
            src="/images/home.jpg"
            alt="Chess experience"
            className="intro-image"
          />
        </div>
        <p className="introduction-text">
          Unlock the full potential of your chess skills with an exclusive
          coaching service tailored for adults. Elevate your chess game and join
          our exclusive community. Discover a richer chess experience today.
        </p>
        <div className="benefits-container">
          {[
            {
              title: "Personalized Guidance",
              text: "Customized coaching for all levels.",
            },
            {
              title: "Elite Expertise",
              text: "Learn from a seasoned chess pro.",
            },
            {
              title: "Flexible Scheduling",
              text: "Chess that fits your lifestyle.",
            },
            {
              title: "Premium Resources",
              text: "Access to exclusive strategies.",
            },
            {
              title: "Networking Opportunities",
              text: "Connect with fellow enthusiasts.",
            },
          ].map((benefit, index) => (
            <div key={index} className="benefit-card">
              <strong>{benefit.title}:</strong>
              <p>{benefit.text}</p>
            </div>
          ))}
        </div>
        <button className="join-button">Join Now</button>
      </section>
      <section className="coach-info">
        <h2 className="sub-title">About the Coach</h2>
        <div className="coach-image-container">
          <img
            src="/images/pic1.jpg"
            alt="Coach Adam Bukojemski"
            className="coach-image"
          />
        </div>
        <div className="coach-description">
          <p>
            <strong>Adam Bukojemski.</strong>
          </p>
          <p>Rated 2380 (English Chess Federation)</p>
          <p>FIDE Rated 2220 (standard), 2296 (rapid), 2248 (blitz)</p>
          <p>
            I am most proud of my achievements as a coach during my time in
            Poland. Now, I bring my experience to enthusiasts and ambitious
            juniors in Bali.
          </p>
        </div>
      </section>
      <footer className="home-footer">
        <p>Enjoy chess in style!</p>
      </footer>
    </div>
  );
};

export default Home;
