import React from "react";

const Coach = () => {
  return (
    <div className="coach-container">
      <h1>Adam Bukojemski</h1>
      <p>
        <strong>Rated 2380</strong> (English Chess Federation)
      </p>
      <p>
        <strong>FIDE Rated:</strong> 2220 (standard), 2296 (rapid), 2248 (blitz)
      </p>
      <p>
        I am most proud of my achievements as a coach when I was working in
        Poland (until 2013).
      </p>
      <p>
        While being responsible for managing and coaching junior chess team in
        the top Polish chess league, I was also coaching individually most
        talented members of the club and succeeding in winning with them:
      </p>
      <ul>
        <li>1st place in the Polish Championship U12 (2010)</li>
        <li>
          1st place in the Polish Championship for Catholic Schools (2010)
        </li>
        <li>1st in Schools’ World Championships U13 (2011)</li>
        <li>1st place in the Polish Championship U14 (2012)</li>
      </ul>
      <p>
        In 2013 I moved to London, England, where I lived until the COVID-19
        pandemic (2020).
      </p>
      <p>
        During that period I focused mainly on working with Chess in Schools and
        Communities charity where I was teaching chess as a part of curriculum
        in primary schools, and I was also coaching more advanced players for
        clubs and national associations like Berkshire Junior Chess Association,
        Kent Junior Chess Association, Knights of Gidea Park Junior Chess Club.
      </p>
      <p>
        Right now, I coach numerous chess enthusiasts and a couple of ambitious
        juniors here in Bali, and I hope that you will join me too!
      </p>
    </div>
  );
};

export default Coach;
